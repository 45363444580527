<template>
<div>
  <el-dialog
    title="试卷分析"
    :visible.sync="centerDialogVisible"
    width="80%"
    center
    @close="closePaperStaticModal">
    <div class="fenxi-fenxi-analysis-more-module">
      <div class="module-content clearfix">
          <div class="fenxi-analysis-section type">
              <div class="fenxi-section-title">
                  <span class="fenxi-slider-bar"></span>
                  总体分析
              </div>
              <div class="fenxi-paper-analysis-table">
                  <div class='fenxi-theader'>
                      <div class="fenxi-td-item">题型</div><div class="fenxi-td-item">数量</div><div class="fenxi-td-item">分值</div><div class="fenxi-td-item">分值占比</div>
                  </div>
                  <div class="fenxi-tbody custom-scroll"></div>
                  <div class="summary clearfix">
                      <div class="fenxi-td-item pull-left">合计</div><div class="fenxi-td-item pull-left"></div><div class="fenxi-td-item pull-left"></div><div class="fenxi-td-item pull-left"></div>
                  </div>
              </div>
          </div>
          <div class="fenxi-analysis-section diff">
              <div class="fenxi-section-title">
                  <span class="fenxi-slider-bar"></span>
                  难度分析
              </div>
              <div id="paper-diff-analysis"></div>
          </div>
          <div class="fenxi-analysis-section point">
              <div class="fenxi-section-title">
                  <span class="fenxi-slider-bar"></span>
                  知识点分析
              </div>
              <table class="paper-point-table" cellspacing=0>
                  <tr class='fenxi-theader'>
                      <th style="min-width: 60px">序号</th>
                      <th style="min-width: 100px">知识点</th>
                      <th style="min-width: 60px" class="fenxi-sort score">分值<span class="fenxi-sort-icon"></span></th>
                      <th style="min-width: 60px">分值占比</th>
                      <th style="min-width: 100px" class="fenxi-sort sn">对应题号<span class="fenxi-sort-icon"></span></th>
                  </tr>
              </table>
          </div>
      </div>
  </div>
    <span slot="footer"></span>
  </el-dialog>
</div>
  
</template>
<script>
export default {
  name: "PaperStatic",
  props: ["info"],
  components: {},
  data() {
    return {
      centerDialogVisible: true,
      pointList: []
    };
  },
  computed: {
    url() {
      if (this.info ) {
        let paperid = this.info.Summary.id
        return {
          id: paperid.id,
          version: paperid.version
        }
      } else {
        return "";
      }
    },
  },
  watch: {
    url:{
      handler(curr){
        this.initPaperStatics(curr)
      },
      immediate:true,
      deep:true
    }, 
  },
  created() {
    var $echarts = $('<script>',{'src':'/static/js/echarts.min.js','type':'text/javascript'})
    this.appendScriptToBody('echarts', $echarts)
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    closePaperStaticModal(){
      this.$emit("closePaperStaticModal")
    },
    initPaperStatics(info){
      var param = {}
      this.startLoading();
      this.fetchServerData_assignment("PaperAnalysis/"+ info.id + "/" + info.version, "get", "", param, (result, status)=>{
          this.stopLoading();
          if(status.success){
            if(this.isValid(result)){
              let questionTypeList = result.questionTypeAnalysis;
              let diffcultyList = result.difficultyAnalysis;
              this.pointList = result.knowledgePointAnalysis;
              if(this.isValidArray(questionTypeList)){
                  var $target = $(".fenxi-paper-analysis-table .fenxi-tbody");
                  var sumScore = 0, sumNumber = 0;
                  for(var i=0;i<questionTypeList.length;i++){
                      var item = questionTypeList[i];
                      var percentage = item.percentage;
                      percentage = Math.round(percentage * 100) / 100;
                      var questionCount = item.questionCount;
                      var score = item.score;
                      var typeId = item.typeId;
                      var typeName = item.typeName;

                      sumScore += score;
                      sumNumber += questionCount;
                      var $tr = $("<div>", {class: "tr-item clearfix"});
                      $target.append($tr);

                      var $td1 = $("<div>", {class: "fenxi-td-item pull-left"});
                      $td1.text(typeName);
                      $td1.appendTo($tr);

                      var $td2 = $("<div>", {class: "fenxi-td-item pull-left"});
                      $td2.text(questionCount);
                      $td2.appendTo($tr);

                      var $td3 = $("<div>", {class: "fenxi-td-item pull-left"});
                      $td3.text(score);
                      $td3.appendTo($tr);

                      var $td4 = $("<div>", {class: "fenxi-td-item pull-left"});
                      $td4.text(percentage + "%");
                      $td4.appendTo($tr);
                  }
                  var $summary = $(".fenxi-paper-analysis-table .summary");
                  $summary.find(".fenxi-td-item:eq(1)").text(sumNumber);
                  $summary.find(".fenxi-td-item:eq(2)").text(sumScore);
                  $summary.find(".fenxi-td-item:eq(3)").text("100%");
              }
              if(this.isValidArray(diffcultyList)){
                  var seriesData = [];
                  for(var i=0;i<diffcultyList.length;i++){
                      var item = diffcultyList[i];
                      var difficulty = item.difficulty;
                      var percentage = item.percentage;
                      var score = item.score;
                      var name = "";
                      var color = "";
                      switch(difficulty){
                          case 1:
                              name = "容易";
                              color = "#98f7f2";
                              break;
                          case 2:
                              name = "较易";
                              color = "#59e8e1";
                              break;
                          case 3:
                              name = "中等";
                              color = "#2DCCC4";
                              break;
                          case 4:
                              name = "较难";
                              color = "#ffdfba";
                              break;
                          case 5:
                              name = "难";
                              color = "#FFBB6C";
                              break;
                      }
                      seriesData.push({
                          name: name,
                          value: percentage,
                          itemStyle: {
                              color: color
                          }
                      })
                  }
                  var option = {
                      tooltip: {
                          show: false
                      },
                      legend: {
                          show: false
                      },
                      series: [
                          {
                              type: 'pie',
                              radius: ['33%', '50%'],
                              center: ['50%', '50%'],
                              data: seriesData,
                              silent: true,
                              label: {
                                  formatter: function(params) {
                                    return params.data.name + "\n" + params.data.value + "%"
                                  },
                                  color: "#4A4A4A",
                                  align: "center",
                              },
                              labelLine: {
                                  color: "#979797"
                              }
                          }
                      ]
                  };
                  this.$nextTick(() => {
                    var $target = $("#paper-diff-analysis");
                    var chart = echarts.init($target[0]);
                    chart.setOption(option);
                  })
              }
              if(this.isValidArray(this.pointList)){
                  this.initPointList(this.pointList);
                  var that = this;
                  $(".fenxi-sort").click(function() {
                      var $target = $(this);
                      $(".fenxi-sort").not($target).removeClass("active");
                      $target.toggleClass("active");
                      var dir = 1;
                      if($(this).hasClass("active")){
                          dir = 0;
                      }
                      if($target.hasClass("score")){
                        that.pointList = that.pointList.sort(that.compare("score", dir))
                      }else if($target.hasClass("sn")){
                        that.pointList = that.pointList.sort(that.compare("sn", dir))
                      }   
                      that.initPointList(that.pointList);
                  });
              }
            }
          }
      })
    },
    compare(property, dir){
      return function(a,b){
          var value1 = a[property];
          var value2 = b[property];
          return dir > 0 ? value1 - value2 : value2 - value1;
      }
    },
    getPointKeyHeight(item){
        var groupSortNumber = item.groupSortNumber;
        var questionSortNumber = item.questionSortNumber;
        var key1 = this.checkIsNum(groupSortNumber);
        if(this.isValidInt(key1, 0)){
            key1 = key1 * 10;
        }else{
            key1 = 0;
        }
        var key2 = questionSortNumber.replace(/[^0-9]/ig,"");
        return parseInt(key1) + parseInt(key2);
    },
    checkIsNum(str) {
        var ChineseItem = ['零','一', '二', '三', '四', '五', '六', '七', '八', '九','十','百','千','万','两']; //十,百
        var numItem = [0,1, 2, 3, 4, 5, 6, 7, 8, 9,10,100,1000,10000,2];
        var doubleNum =[]
        var NumIndex = []
        for (var i = 0; i < str.length; i++) {
            var ChineseOne = str.charAt(i);
            for (var j = 0; j < ChineseItem.length; j++) {
                if (ChineseOne == ChineseItem[j]) {
                    var a = {
                        index: j,
                        seat: i
                    }
                    NumIndex.push(a)
                }
            }
        }
        var double=[];
        var float=[];
        var Num=0;
        for(var k=0;k<NumIndex.length;k++){
            if(NumIndex[k].index == 0){
            
            }else{
                if(k%2 == 0){ //单数
                    float.push(numItem[NumIndex[k].index]) 
                }
                if(k%2 == 1){
                    double.push(numItem[NumIndex[k].index])
                }
            }
        }
        if(double.length  == 0){
            Num = float[0]
        }
        for(var j=0;j<double.length;j++){
            if(double.length<float.length){
                
                if(float[j] == 0){
                    
                }else{
                    Num = Num + double[j]*float[j]
                    if(j == double.length-1 && j<float.length){
                        if(float[j+1]>10){
                            Num = Num * float[j+1]
                        }else{
                            Num = Num + float[j+1]
                        }
                        
                    }
                }
            }else if(double.length == float.length){
                
                if(float[j]==0){
                    Num = Num + double[j]*float[j+1]
                }else{
                    Num = Num + double[j]*float[j]
                }
            }
        }
        return Num;
    },
    initPointList(pointList){
        if(this.isValidArray(pointList)){
            for(var i=0;i<pointList.length;i++){
                var item = pointList[i];
                var keyHeight = 0;
                var questionNumbers = item.questionNumbers;
                if(this.isValidArray(questionNumbers)){
                    var temp = questionNumbers[0];
                    keyHeight = this.getPointKeyHeight(temp);
                }
                item.sn = keyHeight;
            }
            var $target = $(".paper-point-table");
            $target.find(".tr-item").remove();
            for(var i=0;i<pointList.length;i++){
                var item = pointList[i];
                var point = item.name;
                var score = item.score;
                var percent = item.percentage;
                var knowledgePointId = item.knowledgePointId;
                var questionNumbers = item.questionNumbers;

                var $tr = $("<tr>");
                $tr.addClass("tr-item");
                $target.append($tr);
                var $td1 = $("<td>");
                $td1.text(i + 1);
                $td1.appendTo($tr);
                var $td2 = $("<td>");
                $td2.text(point);
                $td2.appendTo($tr);
                var $td3 = $("<td>");
                $td3.text(score);
                $td3.appendTo($tr);
                var $td4 = $("<td>");
                $td4.text(percent + "%");
                $td4.appendTo($tr);
                var $td5 = $("<td>");
                $td5.appendTo($tr);
                if(this.isValidArray(questionNumbers)){
                    var snList = [];
                    for(var j=0;j<questionNumbers.length;j++){
                        var question = questionNumbers[j];
                        var groupSortNumber = question.groupSortNumber;
                        var questionSortNumber = question.questionSortNumber;
                        var sn = questionSortNumber;
                        if(groupSortNumber && groupSortNumber != ""){
                            sn = groupSortNumber + "、" + questionSortNumber;
                        }
                        snList.push(sn);
                    }
                    $td5.text(snList.join("，"));
                }else{
                    $td5.text("无题型");
                }
            }
        }
    },
  },
};
</script>

<style lang='less'>
.fenxi-fenxi-analysis-more-module{
    min-height: 500px;
}
.fenxi-paper-analysis{
    padding: 10px 0;
}
.fenxi-analysis-section{
    margin-bottom: 10px;
    width: 50%;
    padding: 0 20px;
    box-sizing: border-box;
    float: left;
}
.fenxi-analysis-section.point{
    width: 100%;
    margin-top: 30px;
}
.fenxi-section-title{
    font-size: 14px;
    color: #4A4A4A;
    height: 26px;
    line-height: 26px;
    margin-bottom: 10px;
}
.fenxi-slider-bar{
    background: #2DCCC4;
    width: 4px;
    height: 18px;
    display: inline-block;
    position: relative;
    top: 4px;
    margin-right: 3px;
}
.fenxi-fenxi-analysis-more-module table{
    width: 100%;
    border: 1px solid #EBEBEB;
    color: #4A4A4A;
}
.fenxi-fenxi-analysis-more-module table th{
    font-size: 14px;
    color: #4A4A4A;
    font-weight: normal;
    height: 36px;
    line-height: 36px;
    text-align: center;
}
.fenxi-fenxi-analysis-more-module table td{
    border-top: 1px solid #EBEBEB;
    height: 36px;
    line-height: 36px;
    text-align: center;
}
.fenxi-fenxi-analysis-more-module table tr td:first-child,
.fenxi-fenxi-analysis-more-module table tr th:first-child{
    text-align: center;
    width: 100px;
}

.fenxi-fenxi-analysis-more-module table .fenxi-theader{
    background: #EBEBEB;
}
.fenxi-analysis-more{
    font-size: 14px;
    color: #2DCCC4;
    text-align: center;
    cursor: pointer;
}
#paper-diff-analysis{
    width: 100%;
    height: 260px;
}
.fenxi-sort{
    cursor: pointer;
}
.fenxi-sort-icon{
    background-image: url('/static/images/res/icon_order.png');
    background-size: 80px 40px;
    background-position: 0 0;
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    margin-left: 4px;
    top: -1px;
}
.fenxi-sort.active .fenxi-sort-icon{
    background-position-y: -20px;
}

.fenxi-paper-analysis-table{
    width: 100%;
    border: 1px solid #EBEBEB;
    color: #4A4A4A;
}
.fenxi-paper-analysis-table .fenxi-td-item {
    display: inline-block;
    width: 25%;
    text-align: center;
    border-top: 1px solid #EBEBEB;
    height: 36px;
    line-height: 36px;
}
.fenxi-paper-analysis-table .fenxi-theader {
    background: #EBEBEB;
}
.fenxi-paper-analysis-table .fenxi-theader .fenxi-td-item{
    font-size: 14px;
    color: #4A4A4A;
    font-weight: normal;
    height: 36px;
    line-height: 36px;
}
.fenxi-paper-analysis-table .fenxi-tbody{
    max-height: 225px;
    overflow: auto;
}
.custom-scroll{
    overflow-y: auto;
}
.custom-scroll::-webkit-scrollbar {
    width: 4px;
}
.custom-scroll::-webkit-scrollbar-thumb {
    background: #D8D8D8;
    border-radius: 2px;
}
</style>
